import React, { Fragment } from "react";
import { PropTypes } from "prop-types";
import GIC from "../../utils/images/GIC.png";
import TopHeader from "./TopHeader";
import HomeNavbar from "./homelayout/HomeNavbar";
import HomeWelcome from "./homelayout/HomeWelcome";
import BackToTop from "./BackToTop";

const Navbar = () => {

  //SP - Produces one 401 bug (may need to put back though)
  //  useEffect(() => {
  //    authContext.loadUser();
  //  }, []);

  return (
    <Fragment>
      <div className="home-container">
      <TopHeader />
      <HomeWelcome/>
        <h1>
          <div id="home-img">
            <img className="img-fluid" src={GIC} alt="GIC Image"></img>
          </div>
        </h1>
        <HomeNavbar />
        <BackToTop />
      </div>
    </Fragment>  
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: "GlowInColour",
  icon: "fas fa-info-circle",
};

export default Navbar;
