import React, { useState, useContext } from "react";
import { WithNavbar } from "../layout/WithNavbar";
import AuthContext from "../../context/Auth/AuthContext";
import AlertContext from "../../context/alert/alertContext";
import MessageContext from "../../context/message/messageContext";

const AccountServices = () => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const msgContext = useContext(MessageContext);

  const { setAlert } = alertContext;
  const { clearErrors, user } = authContext;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { setMessage } = msgContext;

  const refreshPage = () => {
    window.location.reload();
  };

  const PostData = (prevEmail) => {
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setAlert("Invalid email entered", "danger");
      clearErrors();
      return;
    }
    fetch("/api/auth/update-account", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prevEmail,
        email,
        name,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setAlert(data.error, "danger");
          clearErrors();
        } else {
          setMessage(data.message, "success");
          setTimeout(refreshPage, 4000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="main-container">
      <h1>Account Services</h1>

<p className="acc-details">Update username and email for your account here.</p> 
<p id="acc-note">Note: 
if you change your email, please be aware receipts will still be sent to the address given when you 
registered for the service. If you require help regarding this matter, please contact us through our contact us page.</p>

      <div className="acc-serv-form-container">
        <div id="acc-serv-form">
          <div className="form-group">
            <i className="fas fa-user"></i>

            <input
              type="text"
              placeholder={user.name}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div id="acc-serv-form">
          <div className="form-group">
            <i className="fas fa-at"></i>

            <input
              type="text"
              placeholder={user.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="btn-container">
          <button
            className="submit-btn acc-serv-submit"
            onClick={() => PostData(user.email)}
          >
            Change
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithNavbar(AccountServices);
