import React, { useContext } from "react";
import MessageContext from "../../context/message/messageContext";

const Messages = () => {
  const messageContext = useContext(MessageContext);

  return (
    <div className="alert-container">
      {messageContext.messages.length > 0 &&
        messageContext.messages.map((message) => (
          <div key={message.id} className={`alert alert-${message.type}`}>
            <i className="fas fa-check"></i> {message.msg}
          </div>
        ))}
    </div>
  );
};

export default Messages;
