import React from "react";

export const DrapeSvg = ({ showDrape, drapeColour, scale }) => {

  console.log({drapeColour})

   const transstring = "scale("+scale+")"
  return (
    <div>
      {showDrape && (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          preserveAspectRatio="xMidYMid meet"
          viewBox="192.08029197080293 176.66951050103208 326.87591240875895 246.50323889069537"
          width="322.88"
          height="300.5"
          transform={transstring}>
          <defs>
            <path
              d="M244.6 183.36C291.61 226.15 308.9 265.87 355.44 265.87C400.91 265.87 419.47 223.69 464.5 183.36C471.19 177.38 485.1 176.11 506.24 179.56C511.84 180.47 515.96 185.31 515.96 190.99C515.96 236.82 515.96 363.91 515.96 409.6C515.96 415.44 511.22 420.17 505.38 420.17C442.92 420.17 266.12 420.17 203.66 420.17C197.82 420.17 193.08 415.44 193.08 409.6C193.08 363.7 193.08 235.97 193.08 189.92C193.08 184.33 197.28 179.62 202.84 178.99C224.74 176.5 238.66 177.95 244.6 183.36Z"
              id="d8eqip1Up"
            ></path>
          </defs>
          <g>
            <g>
              <use
                xlinkHref="#d8eqip1Up"
                opacity="1"
                fill={drapeColour?drapeColour:"#bebebe"}
                fillOpacity="1"
              ></use>
            </g>
          </g>
        </svg>
      )}
    </div>
  );
};
