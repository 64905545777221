import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { WithNavbar } from "../layout/WithNavbar";
import "bootstrap/dist/css/bootstrap.css";
import { DrapeSvg } from "./DrapesComponents/DrapeSvg";
import { useDraggable } from "use-draggable";
import SpringBtn from "../../utils/images/ButtonImages/Spring_Drapes_Btn.jpg";
import AutumnBtn from "../../utils/images/ButtonImages/Autumn_Drapes_Btn.jpg";
import SummerBtn from "../../utils/images/ButtonImages/Summer_Drapes_Btn.jpg";
import WinterBtn from "../../utils/images/ButtonImages/Winter_Drapes_Btn.jpg";
import { useHistory } from "react-router";

const Drapes = () => {
  // const authContext = useContext(AuthContext);
  // useEffect(() => {
  //   authContext.loadUser();
  // }, []);
  const colors_red = ["#DD1174", "#AD0C44", "#CC0404", "#953300"];
  const colors_green = ["#BBF7DA", "#073D20", "#00A86B", "#4B5E33"];
  const colors_yellow = ["#f4f3a9", "#FFFF31", "#F9DD4F", "#DDB421"];
  const colors_pink = ["#ffe8ec", "#820b5a", "#F98B88", "#DB9E78"];
  const colors_brown = ["#C1AEAE", "#47260f", "#9C6C4A", "#633016"];
  const colors_blue = ["#89ABE3", "#003366", "#75EFEF", "#326872"];
  const colors_seven = ["#F5F2E8", "#FFFFFF", "#fcfce0", "#ffebcd"];
  const colors_eight = ["#F8E3E3", "#FFECD9"];
  const colors_nine = ["#A8A9AD", "#717577", "#d4af37", "#b0903d"];
  const colors_ten = ["black"];

  const [showDrape, setShowDrape] = useState(false);
  const [scale, setScale] = useState("1");
  const [background, setBackground] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [error, setError] = useState(false);

  const history = useHistory();

  const { targetRef, handleRef } = useDraggable({ controlStyle: true });

  const navigateSeason = (season) => {
    let path = `/drapesapp/${season}`;
    history.push(path);
  };

  const handleDrapeChange = (e) => {
    setShowDrape(!showDrape);
  };

  const handleImageChange = (e) => {
    setError(false);
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setImgPreview(reader.result);
      };
      reader.readAsDataURL(selected);
    } else {
      setError(true);
    }
  };

  const increaseDrape = () => {
    const currentScale = parseFloat(scale);
    const newScale = currentScale + 0.02;
    if (newScale > 1.8) alert("Drape cannot be any bigger");
    else setScale(newScale.toString());
  };

  const decreaseDrape = () => {
    const currentScale = parseFloat(scale);
    const newScale = currentScale - 0.02;
    if (newScale < 0.5) alert("Drape cannot be any smaller");
    else setScale(newScale.toString());
  };
  const moveUp = () => {
    let currentImageTop = document.getElementById("currentImage").style.top;
    let currentImageTopNo = currentImageTop.replace("%", "");
    if (currentImageTopNo <= -90) {
      alert("Cannot move up any higher");
    } else {
      document.getElementById("currentImage").style.top =
        (currentImageTopNo.toString() - 5).toString() + "%";
    }
  };

  const moveDown = () => {
    let currentImageTop = document.getElementById("currentImage").style.top;
    let currentImageTopNo = currentImageTop.replace("%", "");
    let newCurrentImageTop = parseInt(currentImageTopNo.replace("%", ""));
    if (isNaN(newCurrentImageTop)) {
      newCurrentImageTop = 0;
    }
    newCurrentImageTop += 5;
    if (newCurrentImageTop >= 90) {
      alert("Cannot move down any lower");
    } else {
      document.getElementById("currentImage").style.top =
        newCurrentImageTop.toString() + "%";
    }
  };

  const moveRight = () => {
    let currentImageLeft = document.getElementById("currentImage").style.left;
    let currentImageleftNo = currentImageLeft.replace("%", "");
    let newCurrentImageLeft = parseInt(currentImageleftNo.replace("%", ""));
    if (isNaN(newCurrentImageLeft)) {
      newCurrentImageLeft = 0;
    }
    newCurrentImageLeft += 10;
    if (newCurrentImageLeft >= 70) {
      alert("Cannot move right any further");
    } else {
      document.getElementById("currentImage").style.left =
        newCurrentImageLeft.toString() + "%";
    }
  };

  const moveLeft = () => {
    let currentImageLeft = document.getElementById("currentImage").style.left;
    let currentImageleftNo = currentImageLeft.replace("%", "");
    let newCurrentImageLeft = parseInt(currentImageleftNo.replace("%", ""));
    if (isNaN(newCurrentImageLeft)) {
      newCurrentImageLeft = 0;
    }
    newCurrentImageLeft -= 10;
    if (newCurrentImageLeft <= -70) {
      alert("Cannot move left any further");
    } else {
      document.getElementById("currentImage").style.left =
        newCurrentImageLeft.toString() + "%";
    }
  };

  const resetImZoom = () => {
    let myImg = document.getElementById("uploadimg");
    myImg.style.width = "100%";
  };

  const zoomIn = () => {
    let containerWidth = document.getElementById("imgContainer").offsetWidth;
    let myImg = document.getElementById("uploadimg");
    let currWidth = myImg.clientWidth;
    let currHeight = myImg.clientHeight;
    if (currWidth >= containerWidth) {
      alert("Maxium zoom in has been reached");
    } else {
      myImg.style.width = currWidth + 50 + "px";
      myImg.style.height = currHeight + 50 + "px";
    }
  };

  const zoomOut = () => {
    let myImg = document.getElementById("uploadimg");
    let currWidth = myImg.clientWidth;
    // let currHeight = myImg.clientHeight;
    if (currWidth <= 200) {
      alert("Maxium zoom out has been reached");
    } else {
      myImg.style.width = currWidth - 50 + "px";
    }
  };

  return (
    <div className="drapes-page">
      <Container fluid>
        <Row className="flex-md-row">
          <Col className="id-drapes-test" sm={2} xs={6} id="drapes-col-1">
            <p id="color-title">Colours</p>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_red.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_green.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_yellow.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_pink.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_brown.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_blue.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
          </Col>
          <Col className="id-drapes-test" sm={8} xs={12} id="drapes-col-2">
            <div className="container">
              <div className="drapes-container">
                <div className="top-btn-container">
                  {imgPreview ? (
                    <button
                      className="btn-col-one"
                      id="show-drape"
                      onClick={() => handleDrapeChange()}
                    >
                      {showDrape ? "Hide Drape" : "Show Drape"}
                    </button>
                  ) : (
                    <button id="show-drape" className="btn-disabled">
                      Hide Drape
                    </button>
                  )}
                  {imgPreview && (
                    <button
                      className="rst-btn btn-col-three"
                      onClick={() => {
                        setImgPreview(null);
                        resetImZoom();
                        setShowDrape(null);
                      }}
                    >
                      Remove Image
                    </button>
                  )}
                  <button
                    id="show-drape"
                    className="btn-disabled"
                    onClick={() => increaseDrape()}
                  >
                    <i className="fas fa-plus" id="drape-btn"></i>
                  </button>
                  <button
                    id="show-drape"
                    className="btn-disabled"
                    onClick={() => decreaseDrape()}
                  >
                    <i className="fas fa-minus" id="drape-btn"></i>
                  </button>
                </div>
                <div className="img-drape-container">
                  <div className="image-container" id="imgContainer">
                    {error && <p className="errMsg">File not supported</p>}

                    <div className="imgPreview" id="uploadimg">
                      <div className="drape-img" ref={targetRef}>
                        <DrapeSvg
                          ref={handleRef}
                          showDrape={showDrape}
                          drapeColour={background}
                          scale={scale}
                        />
                      </div>
                      {!imgPreview ? (
                        <>
                          <p>Add an image</p>
                          <label
                            className="customFileUpload"
                            htmlFor="fileUpload"
                          >
                            Choose File
                          </label>
                          <input
                            type="file"
                            id="fileUpload"
                            onChange={handleImageChange}
                          />
                          <span>(jpg, jpeg or png)</span>
                        </>
                      ) : (
                        <div>
                          <img
                            src={imgPreview}
                            alt="Uploaded Image"
                            id="currentImage"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-containers">
                <div className="drape-btn-container">
                  {imgPreview && (
                    <button
                      className="rst-btn btn-col-one"
                      type="button"
                      onClick={() => zoomIn()}
                    >
                      Zoom In
                    </button>
                  )}
                  {imgPreview && (
                    <button
                      className="rst-btn btn-col-two"
                      type="button"
                      onClick={() => zoomOut()}
                    >
                      Zoom Out
                    </button>
                  )}
                  {imgPreview && (
                    <button
                      className="rst-btn btn-col-three"
                      type="button"
                      onClick={() => moveUp()}
                    >
                      Move Up
                    </button>
                  )}
                  {imgPreview && (
                    <button
                      className="rst-btn btn-col-one"
                      type="button"
                      onClick={() => moveDown()}
                    >
                      Move Down
                    </button>
                  )}
                  {imgPreview && (
                    <button
                      className="rst-btn btn-col-two"
                      type="button"
                      onClick={() => moveLeft()}
                    >
                      Move Left
                    </button>
                  )}
                  {imgPreview && (
                    <button
                      className="rst-btn btn-col-three"
                      type="button"
                      onClick={() => moveRight()}
                    >
                      Move Right
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col className="id-drapes-test" sm={2} xs={6} id="drapes-col-3">
            <p id="color-title">Neutral</p>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_seven.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_eight.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_nine.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
            <Row className="test-colour-col">
              <div className="c-container">
                {colors_ten.map((color, index) => (
                  <div key={index} className="c-card">
                    <div
                      style={{
                        background: color,
                        filter: "brightness(95%)",
                        boxShadow: color === background ? "0 0 5px #000" : "",
                      }}
                      className="c-box"
                      onClick={() => setBackground(color)}
                    />
                  </div>
                ))}
              </div>
            </Row>
          </Col>
        </Row>
      <Col className="season-button-containers flex-md-row" lg={12} md={12} sm={12}>
          <Col className="season-btn-container" lg={1} md={3} sm={3} xs={3}>
            <img
              alt="summerBtn"
              src={SummerBtn}
              className="season-button"
              id="summer"
              onClick={() => navigateSeason("summer")}
            />
          </Col>
          <Col className="season-btn-container" lg={1} md={3} sm={3} xs={3}>
            <img
              alt="winterBtn"
              src={WinterBtn}
              className="season-button"
              id="winter"
              onClick={() => navigateSeason("winter")}
            />
          </Col>
          <Col className="season-btn-container" lg={1} md={3} sm={3} xs={3}>
            <img
              alt="springBtn"
              src={SpringBtn}
              className="season-button"
              id="spring"
              onClick={() => navigateSeason("spring")}
            />
          </Col>
          <Col className="season-btn-container" lg={1} md={3} sm={3} xs={3}>
            <img
              alt="autumnBtn"
              src={AutumnBtn}
              className="season-button"
              id="autumn"
              onClick={() => navigateSeason("autumn")}
            />
          </Col>
      </Col>
      </Container>

    </div>
  );
};

export default WithNavbar(Drapes);
