import React from "react";
import { WithNavbar } from "../../layout/WithNavbar";

const privacypolicy = () => {
  return (
    <div className="main-container">
      <h1>Privacy Statement</h1>
      <div id="pp-text">
        We take safeguarding online privacy seriously. Please read the whole of
        this statement carefully as it sets out what information we may collect
        from you, how we may use it and your rights in respect of our use and
        the key details of our privacy policy.
        <br />
        From time to time, we may need to change our privacy policy because of
        changes in our organisation, legislation or in our attempts to serve
        your needs better. We will use reasonable efforts to publish any changes
        to our privacy statement. <br />
        GLOW IN COLOUR LTD is responsible for collecting, processing, storing
        and safe-keeping personal and other information as part of providing a
        service and carrying out our regular business activities. We manage
        personal information in accordance with the Data Protection Act 2018 and
        we are registered as a Data Controller with the{" "}
        <a id="pp-link" href="https://ico.org.uk/">
          Information Commissioner’s Office
        </a>{" "}
        Registration Number <div id="reg-number">ZB114654</div>
        <br />
        <br />
        Any questions regarding our processing of personal data should be
        directed to us via {" "}
        <a id="pp-link" href="mailto:hello@glowincolour.com">
        hello@glowincolour.com.
        </a>{" "}
        <br />
        <br />
        <br />
        <strong>SCOPE</strong>
        <br />
        Our Privacy Statement governs any kind of processing where we are acting
        as a data controller or co-controller (including collection, use,
        transfer, storage and deletion) of personally identifiable information
        (any information that may be used to identify a physical person, and any
        other information associated therewith) about natural persons. This
        statement applies to our processing of data collected through any means,
        actively as well as passively, from persons located anywhere in the
        world.
        <br />
        <br />
        We are guided by the following principles when processing data:
        <br />
        <br />
        <ul className="pp-bullets">
          <li>
            We will only collect data for specific and specified purposes; we
            will make it clear at the point when we request your information,
            what we are collecting it for and how we are going to use it;
          </li>
          <li>
            We will not collect data beyond what is necessary to accomplish
            those purposes; we will minimise the amount of information we
            collect from you to what we need to deliver the services required;
          </li>
          <li>
            We will collect and use your personal information only if we have
            sensible business reasons for doing so, such as contacting you via
            email concerning your membership of Glow In Colour Ltd, and
            contacting you with relevant news and updates about the Glow In
            Colour platform from time to time. We may contact you about other
            services from Glow In Colour that may be relevant to you. We will
            never sell or share your personal information with third parties;
          </li>
          <li>
            We will not use your data for purposes other than those for which it
            was collected, accepted as stated within our policy, or with your
            prior consent;
          </li>
          <li>
            We will seek to verify and/or update your data periodically and we
            will accept requests from you for amendment of the data held;
          </li>
          <li>
            We will apply high technical standards to make our processing of
            data secure;
          </li>
          <li>
            Except otherwise stated, we will not store data in identifiable form
            longer than is necessary to accomplish its purpose or as required by
            law.{" "}
          </li>
        </ul>
        <br />
        <br />
        <strong>INFORMATION COLLECTED</strong>
        <br />
        The information we may collect about you could include, but is not
        limited to:
        <br />
        <ul className="pp-bullets">
          <li>Personal details</li>
          <li>Financial details</li>
          <li>Racial or ethnic origin</li>
        </ul>
        <br />
        If we were to obtain special category data from you we will always get
        your consent first.
        <br />
        <br />
        We will collect information from you when:
        <br />
        <ul className="pp-bullets">
          <li>You sign up to our newsletter or mailing list</li>
          <li>You download an opt-in</li>
          <li>You join a course, programme or membership</li>
          <li>
            You contact us for information via our website or social media
            channels, by phone or email
          </li>
          <li>You post on our social media channels, website or blog</li>
          <li>You work with us in a commercial capacity</li>
        </ul>
        <br />
        We may use the information collected to:
        <ul className="pp-bullets">
          <li>
            Allow you to process a booking for a product, course or membership
          </li>
          <li>Create a profile for you on our site</li>
          <li>
            Send you our newsletters or provide you with information, products
            or services that you request from us or which we feel may interest
            you, where you have consented to be contacted for such purposes{" "}
          </li>
          <li>
            Ensure that content from our site is presented to you in the most
            effective manner for you and your computer
          </li>
          <li>
            Allow you to participate in the service you have chosen to do so
          </li>
          <li>Notify you about changes to our service</li>
        </ul>
        <br />
        <br />
        <strong>INFORMATION STORAGE</strong>
        <br />
        If you provide us with your email address it will be stored, along with
        your first name and surname via ConvertKit, who have their own privacy
        policy and will protect your data. You can find out more{" "}
        <a id="pp-link" href="https://convertkit.com/privacy">
          here
        </a>
        <br />
        <br />
        We will never sell or share your personal information with third
        parties.
        <br />
        <br />
        Any financial information taken by Glow In Colour in order to set up an
        account or subscription with us will be processed through Thrivecart,
        who have their own privacy policy and will protect your information. You
        can find out more{" "}
        <a id="pp-link" href="https://legal.thrivecart.com/platform/privacy/">
          here
        </a>
        <br />
        <br />
        <br />
        <strong>LEGAL BASIS FOR PROCESSING YOUR DATA</strong>
        <br />
        The General Data Protection Regulation (GDPR) provides that processing
        of your data shall only be lawful if and to the extent that at least one
        of the following applies:
        <br />
        <ul className="pp-bullets">
          <li>You have consented</li>
          <li>For the performance of a contract</li>
          <li>For compliance with a legal obligation which we must perform</li>
          <li>To protect vital interests of your or another person</li>
          <li>It is in the public interest</li>
          <li>
            It is in the legitimate interests pursued by us or a third party
          </li>
        </ul>
        We collect data for the purposes set out above. Data is managed to
        ensure that it is either erased from our system when it is no longer
        required for the purpose for which it was collected, retained for legal
        reasons or minimised and retained. Any health data collected from you
        has special protection and is limited to that permissible by law. In all
        instances where special category data is collected we will obtain your
        express consent.
        <br />
        <br />
        <br />
        <strong>LINKS FROM OUR SITE</strong>
        <br />
        Our site contains links to and from other websites which are operated by
        individuals and companies over which we have no direct control. If you
        follow a link to any of these websites, please note that these websites
        have their own privacy and terms of use polices. We do not accept any
        responsibility or liability for these policies. We advise you to check
        the policies for third party sites before you submit any personal data
        to the website.
        <br />
        <br />
        <br />
        <strong>MARKETING EMAILS</strong>
        <br />
        We will make it as easy as we can for you to opt out of unwanted
        processing, providing it does not restrict our ability to provide you
        with the primary service you have requested. Please note if you wish to
        unsubscribe from any marketing emails that you have signed up for, you
        can do so by emailing{" "}
        <a id="pp-link" href="mailto:hello@glowincolour.com">
          here{" "}
        </a>{" "}
        or clicking onto the unsubscribe link on the marketing email that was
        sent to you. It may take 24 hours for this to become effective.
        <br />
        <br />
        <br />
        <strong>WEBSITE ANALYTICS & TARGETED MARKETING</strong>
        <br />
        We use website analytics to provide the best user experience and service
        to you and to evaluate and improve our site. We utilise third party data
        analytics service providers Google Analytics to improve our visibility
        and to monitor website browser behaviour and navigation across our site.
        <br />
        These third-party data analytics service providers collect this
        information on our behalf in accordance with our instructions and in
        line with their own privacy policies. Our service providers may collect
        the following data about the way you use our site, which will almost
        always be anonymised and aggregated before reporting back to us:
        <ul className="pp-bullets">
          <li>Number of visitors to our site</li>
          <li>Pages visited whilst using the site and time spent per page</li>
          <li>
            Page interaction information, such as scrolling, clicks and browsing
            methods
          </li>
          <li>
            Source location and details about where users go when they leave the
            site
          </li>
          <li>Page response times and any download errors</li>
          <li>
            Other technical information relating to end user device, such as IP
            address or browser plug-in
          </li>
        </ul>
        <br />
        From time to time we may use the information collected about you to
        present you with targeted advertisements using platforms such as
        Facebook, Twitter, Google and/or Instagram.
        <br />
        <br />
        <br />
        <strong>CHANGES TO OUR POLICY & FUTURE PROCESSING</strong>
        <br/>
        We do not intend to process your personal information except for the
        reasons stated within this privacy notice. If this changes, this privacy
        notice will be amended and placed on our website at
        https://glowincolour.com and we will contact you by email to notify you
        of any changes.
        <br />
        <br />
        <br />
        <strong>POLICY DETAILS</strong>
        <br />
        We continually review our privacy practices and may change our policy
        and privacy statement from time to time. When we do this an amended
        privacy statement will be placed on our website at
        https://glowincolour.com
        <br />
        This privacy notice was published on 5 July 2021 and last updated on 5
        July 2021.
        <br />
        If you are concerned about how we are collecting, using and/or sharing
        your personal information, you can contact our Data Protection Officer
        Tracy Hooper, at hello@glowincolour.com
        <br />
      </div>
    </div>
  );
};

export default WithNavbar(privacypolicy);
