import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/Auth/AuthContext";
import AlertContext from "../../context/alert/alertContext";
import { WithNavbar } from "../layout/WithNavbar";
import { Link } from "react-router-dom";

const Login = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert, removeAlert } = alertContext;
  const { login, error, clearErrors, isAuthenticated } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push("/");
    }

    if (error === "Invalid Credentials - User does not exist") {
      setAlert(error, "danger");
      clearErrors();
    }

    if (error === "Invalid Credentials - Incorrect password") {
      setAlert(error, "danger");
      clearErrors();
    }
    //eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { email, password } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    removeAlert();

    if (email === "" || password === "") {
      setAlert("Please fill in all fields", "danger");
    } else {
      login({
        email,
        password,
      });
    }
  };

  return (
    <div id="login-page-container">
      <div className="form-container" id="login-form-container">
        <div className="form-top-container">
          <h1 className="login-title">
            Account <span className="form-text-primary">Login</span>
          </h1>
          <div className="form-backdrop login-form-color"></div>
        </div>
        <form onSubmit={onSubmit} id="login-form">
          <div className="form-group">
            {/* <label htmlFor="email">Email Address</label> */}
            <i className ="fas fa-at"></i>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={onChange}
            />
          </div>
          {/* <br /> */}
          <div className="form-group">
            {/* <label htmlFor="password">Password</label> */}
            <i className="fas fa-lock"></i>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={onChange}
              required
            />
          </div>
          <input
            type="submit"
            value="Login"
            className="submit-btn login-submit"
          ></input>
        </form>
        <Link id="forgot-link" to="/reset">
          Forgot Password?
        </Link>
      </div>
    </div>
  );
};

export default WithNavbar(Login);
