import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {WithNavbar} from "../layout/WithNavbar";
import GICMock from "../../utils/images/GIC_mockups.jpg";

const Home = () => {
  //Keep user authenticated....
  //  const authContext = useContext(AuthContext);
  //  useEffect(() => {
  //    authContext.loadUser();
  //  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    window.location.href="/prices"
  }

  return (
    <div>
      <div className="text-container">
        <div className="rainbow-strip" />
        <div className="header-block-text" id="block-one">
          <h1>
            The online Colour Analysis Platform for Stylists who want to work
            Globally!
          </h1>
          <br />
          <p>Can you imagine….</p>
          <br />
          Being able to conduct a full, easy to operate and accurate
          <b> Seasonal Colour Analysis</b> service for any client, anytime,
          anywhere in the world?
          <br />
          <br />
          Not being affected by crazy world events like a Pandemic, and keeping
          your Colour Analysis business going unaffected?
          <br />
          <br />
          Offering your clients the same level of service as if face to face,
          but with the ease of online for you and your client?
        </div>

        <hr />
        <br />

        <Container fluid>
          <div className="home-rows-one">
            <Col className="home-rows-one-column-green" lg={9} md={11}>
              <p>A new way...</p>
              <br />
              Currently, you can only do Colour Analysis for clients who are
              close enough to come to you, or you to them.
              <br />
              <br />
              Which was fine before the Pandemic hit. But now that so much has
              changed and working virtually has become much more commonplace,
              you want to be able to offer Colour Analysis online!
              <br />
              <br />
              You might have tried a few ways of doing your Colour Analysis
              online, but none of them have really hit the mark. They’ve been
              tricky to do from a tech point of view and haven’t been as
              professional as you would like.
              <br />
              <br />
              You’re also not entirely convinced how accurate they are, and so
              it doesn’t feel quite right to you offer when you’re not 100% sure
              you’re doing the best you can for your client.
              <br />
              <br />
              <div>
                What you really need is a way of continuing to do your{" "}
                <b>Seasonal Colour Analysis </b>
                services online, that is...
              </div>
              <br />
              <br />
              <p id="word-1">Simple</p>
              <br />
              <p id="word-2">Easy to use</p>
              <br />
              <p id="word-3">Accurate</p>
              <br />
              <p>Glow In Colour is the solution you have been looking for!</p>
            </Col>
            <Col className="home-rows-one-column" lg={4} md={0}></Col>
          </div>
          <div className="home-rows-one">
            <Col className="home-rows-one-column" lg={6} md={4}></Col>
            <div>
              <div className="home-rows-one-column-purple" lg={6}>
                <div id="home-page-demo-img-cont">
                  <img src={GICMock} />
                </div>
                <div>
                <div id="bullets-cont">
                  <p>A simple system that allows you to:</p>
                  <div id="bullets">
                    <ul>
                      <li>Login quickly and easily</li>
                      <li>
                        Upload your clients’ photo (We give you instructions on
                        how to make sure you get a good photo that will give you
                        accurate results every time)
                      </li>
                      <li>
                        Conduct a Seasonal Colour Analysis service that mimics
                        the in-person experience
                      </li>
                      <li>
                        Set your client up with an online colour palette that
                        works like an app on their phone…no having to send out a
                        physical product somewhere around the world!
                      </li>
                    </ul>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Row id="block-two" className="block-text"></Row>
        <br />
        <div className="extended-content-container">
          <Row id="block-three">
            <div className="block-three-txt">
              <hr />
              <p>A little bit of history...</p>
              <div>
                Glow In Colour was created, founded and is run by Tracy Hooper a
                leading colour expert. Tracy teaches Colour Analysis to students
                at the London College of Style.
              </div>
              <br />
              Tracy says “Having taught numerous students the skills they need
              to conduct their own Colour Analysis services, I found myself
              heartbroken when the Pandemic hit as I saw so many of the students
              I had taught find their businesses ground to a halt. They had
              built their business through in-person services, and obviously the
              Pandemic put a stop to all of that!
              <br />
              <br />
              So it got me thinking that there must be a way to conduct Colour
              Analysis online and I set out to try to work something out. I
              spent most of the rest of 2020 working on it, tweaking, testing
              until finally I found a formula that worked.
              <br />
              <br />
              Glow In Colour is the result of that work, and we are thrilled to
              now be able to offer it to Stylists and Colour Consultants to use
              as a tool in their Styling businesses. Never again will anything
              like a crazy Pandemic stop Stylists from carrying on with their
              work!
              <br />
              <br />
              Of course it also means that everyone can now work globally and
              are not restricted to working only with clients who live within a
              50 mile radius.”
              <br />
              <hr />
            </div>
          </Row>
        </div>

        <br />
        <div className="extended-content-container">
          <div className="extended-content">
            <Row id="block-four" className="block-text">
              <div className="block-four-txt">
                <p>Glow In Colour...</p>
                <div className="block-four-cols">
                  <div className="block-four-column">
                    <div>
                      is a simple solution that will enable you to run any of
                      your <b>Seasonal Colour Analysis</b> services online.
                    </div>
                  </div>
                  <div className="vertical" />
                  <div className="block-four-column">
                    <div>
                      Once you have registered and set up your account on the
                      platform, you will have instant access to the{" "}
                      <b>Drapes App!</b>
                    </div>
                  </div>
                  <div className="vertical" />
                  <div className="block-four-column">
                    <div>
                      A demo video page will take you through the simple
                      instructions on how to use the platform and you are good
                      to go! - <br />
                      This includes instructions on getting a good photo from
                      your client to ensure you always get{" "}
                      <b>100% accurate results</b>
                    </div>
                  </div>
                  <div className="vertical" />
                  <div className="block-four-column">
                    <div>
                      You will also have access to the{" "}
                      <b>online colour swatch app</b> for your clients, and
                      instructions on how to set them up.
                    </div>
                  </div>
                  <div className="vertical" />
                  <div className="block-four-column">
                    <div>
                      You will have everything you need to create the same
                      professional level of <b>Seasonal Colour Analysis</b>{" "}
                      services you would deliver to your clients in-person,
                      <br /> so they still have a great experience with you.
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
        <br />
        <br />

        <div id="block-five" className="block-text">
          <div id="block-five-text">
            <p>So what’s the investment?</p>
            <br />
            Glow In Colour is a monthly membership and you will have access to
            the platform for as long as you remain a member.
            <br />
            <br />
            The investment is a one-off joining fee of $99 and then ongoing
            monthly payments of $67/month.
            <br />
            <br />
            <div id="block-five-launch">
              Sign up by clicking the button below!
            </div>
            <br />
            <form onSubmit={handleSubmit}>
              <div id="register-btn-container">
                <button type="submit" id="register-btn">
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>

        <br />

        <br />

        <br />

        <Container className="faqs" fluid>
          <p>FAQ's</p>
          <Row className="questions-container">
            <Col xl={5} lg={6} md={6} sm={6} className="question-container q-1">
              <div id="faq-q">
                I can’t do the clients’ make-up if I am doing their analysis
                online…how do you get round this and still give them the
                information they need?
              </div>
              <div id="faq-a">
                Agreed, you are not going to actually be able to apply their
                make-up for them if you do their analysis online. This is
                probably the only part of the experience that can’t be
                replicated. However you can still give them advice about the
                colours and there is a PDF with some colour examples included in
                their colour swatch app. We also recommend you ask them to bring
                their make-up bag to the consultation and you can go through the
                make-up they currently own so you can tell them what is right
                for them and what might need to be “retired”. You can also, if
                you’d like to, extend the service by finding some products
                online that you would recommend for them. All of the clients’
                who have experienced the Glow In Colour method have been
                absolutely delighted with this slightly amended way of showing
                them their best make-up colours.
              </div>
            </Col>
            <Col xl={5} lg={5} md={5} sm={5} className="question-container q-2">
              <div id="faq-q">
                I’d still like to do the Colour Analysis service with my client
                there, so I can explain it all to them as I go through the
                analysis…can I still do this?
              </div>
              <div id="faq-a">
                We highly recommend that you always do the analysis with your
                client present. It’s so much easier for them to see it all, and
                take it all on board when they see it and you talk to them about
                it as you go through. We recommend doing this via zoom or other
                video meeting where you can share you screen so they can see
                everything on the Glow In Colour platform as you go through the
                process. That way you can answer any questions they may have and
                make sure they still have the same lovely experience they would
                have had with you in person. Full instructions and a
                demonstration are available on the welcome page.
              </div>
            </Col>
            <Col xl={3} lg={3} md={6} sm={5} className="question-container q-3">
              <div id="faq-q">
                How do I get my client to take a good enough photo?
              </div>
              <div id="faq-a">
                In your welcome instructions, you will be given copy and paste
                instructions that you can keep and send to your clients ready
                for their Colour Analysis.
              </div>
            </Col>
            <Col xl={3} lg={3} md={5} sm={5} className="question-container q-1">
              <div id="faq-q">
                Is there a minimum time I have to stay as a member?
              </div>
              <div id="faq-a">
                No, you can stay for as little or as long as you want to. You
                will receive instructions on how to cancel in your confirmation
                email.
              </div>
            </Col>
            <Col
              xl={3}
              lg={3}
              md={10}
              sm={10}
              className="question-container q-2"
            >
              <div id="faq-q">
                You’ve talked about Glow In Colour being for Seasonal Colour
                Analysis. I do Tonal Colour Analysis, can I do that on the
                platform?
              </div>
              <div id="faq-a">
                At the moment, Glow In Colour is only set up for Seasonal Colour
                Analysis. Tonal Analysis may be added at a later date
              </div>
            </Col>
          </Row>
        </Container>

        <div id="block-seven" className="block-text">
          <hr />
          <h3>
            Glow In Colour is the all-in-one solution for Stylists to do
            Seasonal Colour Analysis, for any client, anywhere in the world, no
            matter what chaos is going on globally!
          </h3>
          <br />
          <br />
          <p id="word-1">Simple</p>
          <br />
          <p id="word-2">Easy to use</p>
          <br />
          <p id="word-3">Accurate</p>
          <br />
          <p id="click-text">
            Leave your details below to be the first to hear for the Glow In
            Colour news! 👇
          </p>
          <br />
          <div id="">Sign up by clicking the button below!</div>
          <br />
          <form onSubmit={handleSubmit}>
            <div id="register-btn-container">
              <button type="submit" id="register-btn">
                Register
              </button>
            </div>
          </form>
          <br />
          <br />
          N.B. – The Glow In Colour system will work for any client of any
          gender, age and ethnicity.
          <br />
          <hr />
        </div>

        <br />
        <br />
      </div>
    </div>
  );
};

export default WithNavbar(Home);
