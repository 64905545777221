import React, { useReducer } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";
import authRedcuer from "./AuthReducer";
import setAuthToken from "../../utils/setAuthToken";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "../types";

// const stylistTagId = "1384816"
const glowInColourId = "2653189"
let tag = glowInColourId
const tagURL = `https://api.convertkit.com/v3/tags/${tag}/subscribe`
const convertKitAPI = "OxDbXwJaGEtIGdktCx_g6Q"

//60. setting initial state for authentication
const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authRedcuer, initialState);

  const loadUser = async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      const res = await axios.get("/api/auth");
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

  const register = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/users", formData, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      emailQualification(formData)
      convertKit(formData)
      loadUser();
    } catch (err) {
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  const convertKit = async (formData) => {
  const { email, name } = formData;
  const subscriber = {
     api_key: convertKitAPI,
     email: email,
     first_name:name,
     tags:["2653189", "1384816"]
   };

  try {
     await axios.post(
       tagURL,
       subscriber,
     );
    return {
       statusCode: 200,
       body: 'Email subscribed',
     };
   } catch (err) {
     return {
       statusCode: err.code,
       body: JSON.stringify({ msg: err.message }),
     };
   }
  };

  const emailQualification = async(imageData) => {
    const { data } = imageData;
    await axios.post("/api/uploadedImages", data)
    .then((e) => {
      console.log("Success")
    })
    .catch((e)=>{
      console.error("Error", e)
    })
  }


  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/auth", formData, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      loadUser();
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  //66. logout user - destoy token
  const logout = () => dispatch({ type: LOGOUT });

  //clear errors - clear any errors in the state
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  //Whenever access anything through component from context must add below...
  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        register,
        emailQualification,
        convertKit,
        loadUser,
        login,
        logout,
        clearErrors,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
