import React from "react";
import { Row, Col } from "react-bootstrap";


const Footer = () => {
  function handleSubmit(e) {
    e.preventDefault();
    window.location.href="/contact-us"
  }

  return (
    <footer className="main-footer">
      <Row id="contact-us-bar">
      <Col lg={2}/>
        <Col lg={5} sm={6} className="question-link">
          Have a question? Want more info?
          </Col>
          <Col lg={3} sm={6} id="contact-btn-container">
          <form onSubmit={handleSubmit}>
            <button type="submit" id="contact-btn">Contact Us</button>
          </form>
        </Col>
      <Col lg={2}/>

      </Row>
      <div id="copyright-bar">
        <p className="col-xs">
          Copyright &copy; {new Date().getFullYear()} Glow In Colour Limited |
          All rights reserved |<a href="/privacy-policy">Privacy Policy</a> |
          <a href="/terms-and-conditions">Terms & Conditions</a> |
          <a href="/contact-us">Contact Us</a>
        </p>
        <p id="built-by">Site built by
        <a href='mailto:scottjamesphillips@gmail.com'>Scott Phillips</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
