import React, { useState, useContext, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";
import AlertContext from "../../context/alert/alertContext";
import MessageContext from "../../context/message/messageContext";
import { WithNavbar } from "../layout/WithNavbar";

const Reset = () => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const messageContext = useContext(MessageContext);
  const history = useHistory();

  const { setAlert, removeAlert } = alertContext;
  const { setMessage, removeMessage } = messageContext;
  const { clearErrors } = authContext;
  const [password, setPasword] = useState("");
  const { token } = useParams();
  console.log(token);

  const onSubmit = (e) => {
    e.preventDefault();

    removeAlert();
    removeMessage();

    fetch("/api/auth/new-password", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        token,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.error) {
          setAlert(data.error, "danger");
          clearErrors();
        } else {
          setMessage(data.message, "success");
          clearErrors();
          history.push("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Fragment>
      <div className="new-pw-container">
        <div className="form-container" id="new-password-form">
          <div className="form-top-container">
            <h1 className="login-title">
              New <span className="form-text-primary">Password</span>
            </h1>
            <div className="form-backdrop login-form-color"></div>
          </div>

          <form onSubmit={onSubmit} id="login-form">
            <div className="form-group">
            <i className ="fas fa-lock"></i>
              <input
                type="password"
                placeholder="Enter a new password"
                value={password}
                onChange={(e) => setPasword(e.target.value)}
                required
                minLength="6"
              />
            </div>
            <input
              type="submit"
              value="Set New"
              className="submit-btn login-submit new-pw-btn"
            ></input>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default WithNavbar(Reset);

//   const [user, setUser] = useState({
//     email: "",
//     password: "",
//   });

//Method taken from https://www.youtube.com/watch?v=NOuiitBbAcU
//   const PostData = () => {
//     fetch("/api/auth/reset-password", {
//       method: "post",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         password,
//       }),
//     })
//       .then((res) => res.json())
//       .then((data) => {
//         if (data.error) {
//           setAlert(data.error, "danger");
//           clearErrors();
//         } else {
//           setAlert(data.message, "success");
//           //   history.push("/");
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   return (
//     <div className="container">
//       <div className="form-container">
//         <h1>
//           Reset <span className="text-primary">Password</span>
//         </h1>
//         <input
//           type="password"
//           placeholder="Enter new password"
//           value={password}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <button
//           className="btn waves-effect waves-light #64b5f6 blue darken-1"
//           onClick={() => PostData()}
//         >
//           Reset Password
//         </button>
//         <h5>
//             <Link to="/login">Don't have an account?</Link>
//         </h5>
//       </div>
//     </div>
//   );
// };

// export default WithNavbar(Reset);
