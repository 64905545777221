import React, { Fragment, useContext, useState } from "react";
import AuthContext from "../../../context/Auth/AuthContext";
import { Link } from "react-router-dom";
import LogoutModal from "../LogoutModal"

const HomeWelcome = () => {
  const authContext = useContext(AuthContext);
  var capFirstName;

  //SP - Produces one 401 bug (may need to put back though)
  // useEffect(() => {
  //   authContext.loadUser();
  // }, []);

  const { isAuthenticated, user } = authContext;
  const [showModal, setShowModal] = useState(null);

  if (user && user.name) {
    var fullName = user.name.split(" "),
      firstName = fullName[0];
    capFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  }

  const authLinks = (
    <Fragment>
      <li>
        <div>Hi {firstName}!</div>
      </li>
      <li>
        <Link to="/account-services">Account</Link>
      </li>
      <li>
        <div id="logout-link" onClick={() => setShowModal(true)}>
          Logout
        </div>
      </li>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <li>
        <Link to="/login">Login</Link>
      </li>
    </Fragment>
  );

  return (
    <Fragment>
        <div className="home-welcome">
          <a href="https://glowincolour.club/" id="swatches-link">
          <p><i id="swatches-arrow" className="fas fa-arrow-left"></i>Colour Swatches Website</p>
          </a>
          {showModal?<LogoutModal onClose={()=> setShowModal(false)} showModal={showModal}/>:null}
          <ul>{isAuthenticated ? authLinks : guestLinks}</ul>
        </div>
    </Fragment>
  );
};

export default HomeWelcome;
