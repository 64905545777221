import React from 'react'
import { WithNavbar } from '../../WithNavbar';

const Cancel = () => {
    return (
        <div className="main-container">
            <h1>Cancel Payment</h1>
            Payment cancelled you have not been charged, please navigate back to the home page using the nav bar above.
        </div>
    )
}

export default WithNavbar(Cancel);
