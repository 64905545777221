import React, { Fragment, useContext } from "react";
import AuthContext from "../../../context/Auth/AuthContext";
import { Link } from "react-router-dom";

const HomeNavbar = () => {
  const authContext = useContext(AuthContext);

   const { isAuthenticated } = authContext;

  const authLinks = (
    <Fragment>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/drapesapp">Drapes</Link>
      </li>
      <li>
        <Link to="/demo">Demo Videos</Link>
      </li>
      <li>
        <Link to="/contact-us">Contact Us</Link>
      </li>
    </Fragment>
  );
  const guestLinks = (
    <Fragment>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/register">Register</Link>
      </li>
      <li>
        <Link to="/contact-us">Contact Us</Link>
      </li>
    </Fragment>
  );

  return (
    <div className="home-navbar">
      <ul>{isAuthenticated ? authLinks : guestLinks}</ul>
    </div>
  );
};

export default HomeNavbar;
