import React, { useState, useEffect } from "react";
import Register from "../auth/Register";

const Success = () => {
  
  const [checkoutData, setCheckoutData] = useState(null);
  const [loading, setLoading] = useState(true);

  var params = new URLSearchParams(window.location.search);
  var id = params.get("id");

  useEffect(() => {
  fetch("api/payment/checkout-session?id=" + id)
    .then((response) => response.json())
    .then((session) => {
      console.log("Success:", session);
      setCheckoutData(JSON.stringify(session));
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      setCheckoutData(null)
      console.log("Error:", error);
    });
  });

  return (
    <div>
      <Register loading={loading} checkoutData={checkoutData}/>
      {/* <div>{loading && <Spinner />}</div>
      <div>
        <div>{checkoutData != null ? <CreateAccount /> : <AccessDenied />}</div>
      </div> */}
    </div>
  );
};

export default Success;
