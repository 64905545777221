import React, { PureComponent } from "react";
import Navbar from "./Navbar";
import Footer from "../layout/Footer";

export function WithNavbar(Component) {
    class WithNavbar extends PureComponent {
      render() {
        return (
          <>
            <Navbar />
            <Component {...this.props} />
            <Footer/>
          </>
        );
      }
    }
  
    return WithNavbar;
  }