import React, { useReducer } from "react";
import { v4 as uuidv4 } from 'uuid';
import MessageContext from "./messageContext";
import messageReducer from "./messageReducer";
import { SET_MESSAGE, REMOVE_MESSAGE } from "../types";
 
const MessageState = props => {
  const initialState = {
    messages: []
  };

  const [state, dispatch] = useReducer(messageReducer, initialState);
 
  //set alert
  const setMessage = (msg, type, timeout = 5000) => {
    const id = uuidv4;
    dispatch({
      type: SET_MESSAGE,
      payload: { msg, type, id }
    });
    
    setTimeout(() => dispatch({type:REMOVE_MESSAGE, payload:id}), timeout)
  };
 
  //remove alert
  const removeMessage = () => {
    dispatch({
      type: REMOVE_MESSAGE
    });
  };
 

  return (
    <MessageContext.Provider
    value={{
      messages: state.messages,
      setMessage,
      removeMessage
    }}>
      {props.children}
    </MessageContext.Provider>
  );
};
 
export default MessageState;