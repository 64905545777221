import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS
} from '../types';

export default (state, action) => {
    switch(action.type){

        //65. Login success same behaviour as register
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            //63. put token in local storage
            localStorage.setItem('token', action.payload.token);
        return{
            ...state,
            ...action.payload,
            isAuthenticated: true,
            loading: false
        }

        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            //63. remove token from storage, set error = payload, in this case 
            //thats the error returned from backend/db
            //64. do the same action for AUTH_ERROR
            //66. logout same as login_fail, register_fail etc 
            localStorage.removeItem('token');
        return{
            ...state,
            token: null,
            isAuthenticated: false,
            loading: false,
            user: null,
            error: action.payload
        }

        //63. Function remove errors from screen
        case CLEAR_ERRORS:
        return{
            ...state,
            error:null
        }

        case USER_LOADED:
        return{
            ...state,
            isAuthenticated: true,
            loading: false,
            user: action.payload
        }

        default:
        return state;
    }
}