import React, { useState, useEffect } from "react";
import "./App.css";

import AuthState from "./context/Auth/AuthState";
import setAuthToken from "./utils/setAuthToken";
import ApplicationFiles from "../src/components/routing/ApplicationFiles"

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {

const [isLoading, setLoading] = useState(true);

function fakeRequest() {
  return new Promise(resolve => setTimeout(() => resolve(), 2500));
}
 
useEffect(() => {
  fakeRequest().then(() => {
    const el = document.querySelector(".loader-container");
    if (el) {
      el.remove();
      setLoading(!isLoading);
    }
  });
}, []);

if (isLoading) {
  return null;
}

  return (
    <AuthState>
      <ApplicationFiles/>
    </AuthState>
  );
};

export default App;
