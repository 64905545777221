import { SET_MESSAGE, REMOVE_MESSAGE } from "../types";
 
export default (state, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      };
    case REMOVE_MESSAGE:
      return {
        ...state,
        messages: []
      };
    default:
      return state;
  }
};