import React, { useState, useContext  } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";
import AlertContext from "../../context/alert/alertContext";
import { WithNavbar } from "../layout/WithNavbar";
import MessageContext from "../../context/message/messageContext";

const Reset = () => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const msgContext = useContext(MessageContext);
  const history = useHistory();
  const [email, setEmail] = useState("");

  const { setAlert } = alertContext;
  const { clearErrors } = authContext;
  const { setMessage } = msgContext;

  //Method taken from https://www.youtube.com/watch?v=NOuiitBbAcU
  const PostData = () => {
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      setAlert("Invalid email entered", "danger");
      clearErrors();
      return;
    }
    fetch("/api/auth/reset-password", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setAlert(data.error, "danger");
          clearErrors();
        } else {
          setMessage(data.message, "success");
          history.push("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="reset-pw-container">
      <div className="form-container" id="reset-password-form">
        <div className="form-top-container">
          <h1 className="reg-title">
            Forgot <span className="form-text-primary">Password</span>
          </h1>
          <div className="form-backdrop reg-form-color"></div>
        </div>
        <div id="reg-form">
          <div className="form-group">
            <i className="fas fa-at"></i>

            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="btn-container">
          <button
            className="reset-btn submit-btn reg-submit"
            onClick={() => PostData()}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithNavbar(Reset);
