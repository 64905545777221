import React from "react";
import { WithNavbar } from "../layout/WithNavbar";
import { Container, Row, Col } from "react-bootstrap";
import Wardrobe from "../../utils/images/Wardrobe-Min.jpg";
import ContactForm from "../layout/ContactUs-Form";

const ContactUs = () => {
  // const authContext = useContext(AuthContext);
  // useEffect(() => {
  //   authContext.loadUser();
  // }, []);

  return (
    <div className="main-container">
      <h1 className="page-title">Contact Us</h1>
      <div className="contact-container">
        <Container>
          <Row>
            <Col lg={6} md={12} sm={12} id="contact-us-text">
              Hello there!
              <br />
              <br />
              Here at Glow In Colour, we love to hear from you.
              <br />
              <br />
              If there's anything we can do to help or you have any questions
              about the Glow In Colour Platform, please do get in touch using
              the contact form to the right.
              <br />
              <br />
              <img
                id="contact-us-img"
                src={Wardrobe}
                alt="Wardrobe Image"
              ></img>
            </Col>
            {/* <Col lg={1}/> */}
            <Col lg={6} md={8} sm={12} id="contact-us-rhs">
            <ContactForm />
              
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default WithNavbar(ContactUs);