import React from "react";
import { WithNavbar } from "../layout/WithNavbar";
import { Container, Row, Col } from "react-bootstrap";
import ColourfulMe from "../../utils/images/Colourful_Me_Min.jpg";

const About = () => {
  return (
    <div className="main-container">
      <h1>About</h1>
      <div className="about-container">
        <Container fluid>
        <Row className="about-row-1">
          <Col lg={4} md={6} sm={12} id="colourfulme-cont">
            <div>
              <img id="colourfulme" src={ColourfulMe} alt="ColourfulMeImg" />
            </div>
          </Col>
          <Col lg={8} md={6} sm={12}>
            <div className="about-cont-1">
              <div id="intro-sentence">
                Hey there! I'm Tracy Hooper and Glow In Colour is my creation!
              </div>
              <br />
              <div id="about-text-1">
                I've been a Personal Stylist since 2008, having been in the
                Fashion Industry my whole working life. In fact I'm a bit of a
                serial entrepreneur as my previous business was as a Designer
                and Dressmaker.
                <br />
                <br />
                As part of my Styling work, I started working with the The
                London College of Style, firstly writing a Colour Analysis
                course and then teaching it as well.
                <br />
                <br />
                But because of my connections to the numerous Stylists I had
                connect with over the years, it upset me hugely to see their
                businesses take such a nose-dive when the Covid pandemic hit.
                Literally everybody's businesses ground to a halt and they were
                left not knowing what they were going to do. I saw the Stylists
                start to offer some of their services online but Colour Analysis
                we had always assumed couldn't be done online.
                <br />
                <br />
                But something needed to be done, so I set about finding a way to
                make it possible. I spent most of the pandemic working on it,
                trying different ways to see if it could be done and testing it
                out as well to make sure it was 100% accurate.
                <br />
                
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} className="about-cont-2">
            <br />
            <br />
            <br />
            <hr />
            <div id="about-text-2">
            <br />
                I knew I needed to create something that was professional, gave
                accurate results every single time, was easy to use, gave the
                client as good an experience as if they were receiving the
                service in person, and made them feel like they had had a lovely
                pampering service, the same as they would face to face.
                <br />
              <br />
              Glow In Colour delivers all of this and more, and if any of what I
              have said resonates with you then I have created this for you and
              I am so excited that you're here!
              <br />
              <br />
              Never again be completely stumped if a major worldwide event stops
              us working face to face with our clients. Glow In Colour means you
              can do your Colour Analysis Consultations with any clients,
              anywhere in the world, at any time.
              <br />
              <br />
            </div>
          </Col>
        </Row>
        </Container>
      </div>
    </div>
  );
};

export default WithNavbar(About);
