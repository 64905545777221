import React from "react";

const LoadingScreen = () => {
  return (
    // <div className="loading-container">
    //     <img id="rolling-spinner" src={RollingSpinner5px} alt="Rolling_Spinner_Img" />
    // </div>

    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export default LoadingScreen;
