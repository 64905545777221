import React, { useReducer } from "react";
import { v4 as uuidv4 } from 'uuid';
import AlertContext from "./alertContext";
import alertReducer from "./alertReducer";
import { SET_ALERT, REMOVE_ALERT } from "../types";
 
const AlertState = props => {
  const initialState = {
    alerts: []
  };

  const [state, dispatch] = useReducer(alertReducer, initialState);
 
  //set alert
  const setAlert = (msg, type, timeout = 3000) => {
    const id = uuidv4;
    dispatch({
      type: SET_ALERT,
      payload: { msg, type, id }
    });
    
    setTimeout(() => dispatch({type:REMOVE_ALERT, payload:id}), timeout)
  };
 
  //remove alert
  const removeAlert = () => {
    dispatch({
      type: REMOVE_ALERT
    });
  };
 
  
 
  return (
    <AlertContext.Provider
    value={{
      alerts: state.alerts,
      setAlert,
      removeAlert
    }}>
      {props.children}
    </AlertContext.Provider>
  );
};
 
export default AlertState;
