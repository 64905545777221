import { WithNavbar } from "../layout/WithNavbar";

const ContactThanks = () => {
  return (
    <div className="main-container">
      <h1>Thank You!</h1>
      <div>
        Thank you for contacting us, we will look into your query and get back
        to you!
      </div>
    </div>
  );
};

export default WithNavbar(ContactThanks);
