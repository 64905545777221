import React from "react";
import { WithNavbar } from "../../layout/WithNavbar";

const termsconditions = () => {
  return (
    <div className="main-container">
      <h1>Terms and Conditions</h1>
      <div id="tc-text">
      <strong>INTRODUCTION</strong>
      <br/>
<div id="mission-text">Our mission at Glow In Colour Ltd is to provide tools to make the job of the Personal Stylist easier and more accessible in the online space.  We believe that our tools will help you to take your business to the next level, and in particular to be able to engage with clients anywhere, at anytime, no matter yours or their geographical location.  We aim to help you get the most out of the tools and so have created some terms of use for you to help.  Please contact us if there is ever a problem or if you feel we could enhance your use of the service even more.</div>
<br/>
<br/>

These Terms and Conditions set out how you (the User) can use this Site.
<br/>
Please read them carefully.
<br/>
The GLOW IN COLOUR Privacy Statement is set out in a separate page and confirms how we use data relating to you and it forms part of these Terms of Use.
<br/>
Separate legal terms apply to purchases made via this Site.
<br/>
These terms may change from time and the terms in force will be displayed on this page.
<br/>
<br/>
<br/>

<strong>ABOUT US AND HOW TO CONTACT US</strong>
<br/>
GLOW IN COLOUR is a Ltd company, incorporated and registered in England and Wales with company number <div id="reg-number">13445869</div>
<br/>
The registered office is: Brackens, Lower Wokingham Road, Crowthorne, Berkshire, RG45 6BT, UK.
<br/>
You can contact us in writing via hello@glowincolour.com or by post at the registered office address.
<br/>
<br/>
<br/>

<strong>GLOW IN COLOUR PLATFORM AND WEBSITE USE</strong>
<br/>
By visiting glowincolour.com you are consenting to our terms and conditions as set out below.  Should you not wish to accept the terms and conditions of this Site in full you should cease using this Site immediately.
<br/>
<br/>

By registering for and using the Glow In Colour Platform, you consent and warrant that you have  have the full right and authority to enter into this Agreement and that you are under no legal disability or contractual restriction that prevents you from entering into this Agreement. 
<br/>
<br/>

This website, glowincolour.com will be referred to as the “Site”.  All visitors to the Site will be referred to as “User” “You” or “Your”. As a user, you will be bound by these Terms of Use which may also be referred to from time to time as Terms and Conditions. The terms “We” “Us” and “Our” refer to Glow In Colour Ltd. Accessing and using the Site constitutes acceptance of the Terms of Use.
<br/>
<br/>
By using this Site you agree to the Terms and Conditions without modification. We reserve the right to amend the Terms of Use of this Site and on doing so we will update these Terms and Conditions.
<br/>
<br/>
This Site is intended for use by persons who are a minimum of 18 years old. 
<br/>
<br/>
You must not copy, share or disseminate the information on this site which is subject to copyright for which all rights are reserved.
<br/>
<br/>
This Site is written in English and we do not take responsibility for any translations which are applied to this Site.
<br/>
<br/>
In using this Site you may encounter comments and/or content supplied by other users. The information contained within user comments is not verified or approved by us. The views expressed by other users on the Site do not represent our views or values.
<br/>
<br/>
<br/>
<strong>GLOW IN COLOUR LTD ACCOUNT</strong>
<br/>
In order to access the Glow In Colour platform, users must register for and set up an account.  The information you provide in setting up that account must be true, accurate and complete.  Users may make any changes necessary to the registration information via the “My Account” page.  Users are responsible for maintaining the confidentiality of their account and passwords and may not pass on their account details and passwords to anybody else.  Users may not use any other users’ account.  
<br/>
<br/>
Users acknowledge that failure to sign out of their account at the end of each session may result in unauthorised use of the account.  Glow In Colour Ltd will not be responsible for any loss or damage that may result if they fail to comply with these conditions.
<br/>
<br/>
<br/>
<strong>PRICING OF SERVICES AND MEMBERSHIPS</strong>
<br/>
Some aspects of the Glow In Colour Service may be provided for a one off fee or a recurring membership fee or charge.  If you elect to use the paid aspects of the Service, you are agreeing to the pricing and payment terms listed.  These pricing and payment terms may be updated from time to time.  Glow In Colour may add new services or memberships for additional fees and/or charges, or amend fees or charges for existing services at any time, at its’ sole discretion. However, the price you agreed to on registration is the ongoing monthly fee you will be charged for the lifetime of your membership.  If you decide to leave at any time and then want to re-join, you will be subject to the charges or fees that are current at the time or re-joining.
<br/>
<br/>
Recurring membership charges will be deducted from your chosen payment source, at the time of registration and then monthly on the same date each month as the date you registered.
<br/>
<br/>
<br/>
<strong>TERMS</strong>
<br/>
The content on this Site, and the associated social media channels (via Instagram @tracyjaynehooper) and email marketing, will include information on Colour Analysis specifically and Personal Styling more generally. All content is intended for informational purposes only. None of the content in emails, blog posts or social media posts should be construed as specific Colour Analysis, Personal Styling or Business advice. 
<br/>
<br/>
In addition to these Terms of Use please be aware of our Privacy Policy which include key terms which are set out separately on our Site.
<br/>
<br/>
<br/>
<strong>CHARGING CLIENTS FOR USE OF THE GLOW IN COLOUR SERVICE</strong>
<br/>
The Glow In Colour platform is intended for use by qualified Colour Professionals as a chargeable service to their clients.  Glow In Colour do not have any recommendations as to how much should be charged for the Colour Analysis service, or any payment information or details due to the user from the client.
<br/>
<br/>
Users accept total responsibility for keeping accurate records and paying any and all relevant tax related to earnings from the use of the Glow In Colour platform.
<br/>
<br/>
<br/>
<strong>REFUND POLICY</strong>
<br/>
Glow In Colour does not offer refunds for membership subscriptions.
<br/>
<br/>
If you no longer wish to be a member of the Glow In Colour Platform, you are free to leave at any time.  You can cancel your membership by following the instructions in your welcome email, by going to the “My Account” section of the platform and clicking the “Cancel My Membership” option, or by emailing us at hello@glowincolour.com.  Your membership will continue until the end of the billing cycle so you will continue to have access until the day your next payment is due. Once cancelled, you will no longer have access to the platform or any information you may have saved in your account.
<br/>
<br/>
You can rejoin at any time, by re-registering via the Glow In Colour website at https://glowincolour.com.
<br/>
<br/>
<br/>
<strong>FREE TRIALS</strong>
<br/>
From time to time Glow In Colour may offer free trials of membership to allow you to trial our service before you commit. We reserve the right to set eligibility requirements for any free trials or other offers.  At the end of the trial period you will be charged the relevant membership fee at the time, for the next subscription period, via your nominated payment method.  You are free to cancel your free trial at any time before your membership fee is taken. Please see the website for details of any current offers or trial periods.
<br/>
<br/>
<br/>
<strong>RULES OF USE OF THE GLOW IN COLOUR PLATFORM</strong>
<br/>
By subscribing to any of the Glow In Colour services or platform, you agree to not engage in any of the following prohibited activities:
<ul id="tc-bullets">
<li>Copying, distributing or disclosing any part of the Service or Platform in any medium, including without limitation, any automated or non-automated “scraping”</li>
<li>Transmitting spam or any other unsolicited email</li>
<li>Using the Platform or Service to promote, support or incite any hostility, violence or discrimination of any sort.</li>
<li>Taking any action whatsoever that imposes or may impose an unreasonable or disproportionately large load on our infrastructure.</li>
<li>Uploading invalid data, worms, viruses or other software agents through the Platform or Service</li>
<li>Interfering in any way with the proper working of the Platform or Service</li>
<li>Harvesting or collecting any account or client names or any other personally identifiable information, from the Platform or Service, without consent from the User and Glow In Colour</li>
<li>Using the Platform or Service for any commercial gain, other than that specified as allowable in these Terms and Conditions i.e. chargeable services to clients using the Glow In Colour Platform</li>
<li>Accessing any content on the Platform or Service through any means other than those provided or authorized by Glow In Colour</li>
<li>Attempting to interfere with or compromise the system integrity or security, or decipher any transmissions to or from the servers running the Service</li>
<li>Using ANY automated system including but not limited to robots, spiders, offline readers etc, in a way that sends more requests or messages to the Glow In Colour servers than any human can reasonably produce in the same period of time using a conventional online web browser</li>
<li>Uploading any sexually explicit or pornographic material to the site or any links to such content</li>
<li>Imitating any other person or misrepresenting your affiliation with a person or entity in any way, conducting fraud, hiding or attempting to hide your identity</li>
<li>Bypassing any measures Glow In Colour may use to prevent or restrict access to the Platform or Service, including but not limited to, features that would prevent or restrict use or copying of any material, or enforce limitations on use of the Platform or it’s content therein</li>
<li>Copying, imitating or using, in whole or in part, the look and feel of the Plaform, including but not limited to all page headers, custom graphics, buttons, button icons, scripts, without the prior written consent on Glow In Colour.</li>
</ul>
<br/>
Glow In Colour may, without prior notice, change the Service or Platform, the features of the Service or Platform, create usage limits on the Service or Platform or stop providing the Service or the Platform, to you in particular or to all users.  
<br/>
<br/>
Glow In Colour may temporarily or permanently suspend or terminate your access to the Platform or Service, without notice and without liability for any reason, or for no reason, including if in our sole discretion you violate any provision of this Agreement.  In case of your access being suspended either temporarily or permanently, you continue to be bound by the terms of this Agreement.
<br/>
<br/>
In case of your account being suspended, either temporarily or permanently for any reason, you must obtain written permission prior to establishing another account with Glow In Colour.  We reserve the right to ban you permanently from the Service in this case.
<br/>
<br/>
You may not have more than one active account on the Glow In Colour Platform at any time without the prior written consent of Glow In Colour.
<br/>
<br/>
You are completely responsible for any and all your interactions with other Platform or Community users, whosoever they may be.  Glow In Colour is under no obligation to settle any disputes between you and other users, and will have no liability for your actions and interactions with other users, or for any other users action or inaction.
<br/>
<br/>
<br/>
<strong>SITE OPERATION</strong>
<br/>
We do not guarantee the availability of any Site or content. We reserve all rights to withdraw, suspend or restrict the content at any time for any reasons. Where possible, we will give you reasonable notice if the Site is to be suspended. 
<br/>
<br/>
We do not guarantee that this Site will be secure or free from bugs or viruses. 
<br/>
<br/>
You are responsible for configuring your information technology, computer programmes and platform to access our Site. You should use your own virus protection software. 
<br/>
<br/>
We do not guarantee that the Glow In Colour Platform will always be available and will not be liable for any loss of income you suffer as a result of of the site being available for any technical or any other reason.  In the event of the site suffering a technical failing for any reason, Glow In Colour will endeavour to find and resolve the problem as quickly as possible and minimise the down time as much as is possible.  If the Glow In Colour Platform will be down for any period of time or is known about in advance, we will contact you via email to let you know the details including when we believe the Platform will be running as normal again.
<br/>
<br/>
<br/>
<strong>PRIVACY POLICY</strong>
<br/>
Our business has a privacy policy. The key elements of that policy are set out on this Site in our Privacy Statement, last updated 5 July 2021. The Privacy Statement can be found at https://glowincolour.com/privacy-policy 
<br/>
<br/>
<br/>
<strong>INTELLECTUAL PROPERTY RIGHTS</strong>
<br/>
This Site contains intellectual property created and owned by GLOW IN COLOUR LTD unless otherwise stated. The Intellectual Property includes all content on the Site. Content means any text, graphics, audio and video and also extends to the page layouts of this Site and any social media channels and emails. All photographs and videos on this website and the connected social media channels are subject to copyright. 
<br/>
<br/>
You may not use our intellectual property in any way. This includes but is not limited to republishing or sharing any text, graphics, audio and video and also extends to the page layouts of this Site and any social media channels and emails.
<br/>
<br/>
Should you wish to utilise the content on this Site please contact hello@glowincolour.com to make your request. You must receive written permission to repurpose, copy and/or modify any content on this Site, or content which is shared to you by email or on social media.
For all copyright requests please contact Tracy Hooper via hello@glowincolour.com
<br/>
<br/>
<br/>
<strong>LOSS OR DAMAGE</strong>
<br/>
Notwithstanding any of these terms, we do not intend to exclude or limit our liability to you where it would be unlawful to do so. We do not exclude or limit liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
<br/>
<br/>
We have no liability to You for any loss of profit, loss of business, business interruption or loss of business opportunity. To the maximum extent permissible at law, our aggregate liability to you in connection with these terms howsoever arising in contract, negligence or otherwise, is limited to £250.
<br/>
<br/>
If defective digital content that we have supplied damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation.
<br/>
<br/>
<br/>
<strong>REFUND POLICY</strong>
<br/>
Our full refund policy is available here:  https://glowincolour.com/refund-policy
<br/>
<br/>
Should you decide to purchase a coaching programme, membership of the Glow In Colour platform or any personalised product from this Site then you will enter into a contract of sale with us at the point of sale.
<br/>
<br/>
If you make an electronic purchase then you will be sent a link for a direct download.  At the point of purchase, you will be asked to confirm your express consent to receiving the download immediately. In agreeing to receive the download at the point of purchase you will lose your right to cancel and your right to a refund.
<br/>
<br/>
Should you have any questions about your purchase we will be happy to assist. 
<br/>
<br/>
<br/>

<strong>DISCLAIMERS</strong>
<br/>
The Glow In Colour Platform is designed for use by qualified Personal or Colour Stylists.  The system works for the Seasonal Colour Analysis method, and is appropriate and relevant for any client of any race, colour, ethnicity, age, ability, gender and sexual orientation.
<br/>
<br/>
Although reasonable efforts are made to update the information on this Site, we make no representations, warranties or guarantees, whether express or implied, that the content on our Site is accurate, complete or up to date.
<br/>
<br/>
The content on this Site does not constitute Colour Analysis training or advice. Should you require advice under any of these heads then you should contact a professional directly. Viewing the information on this Site does not constitute a contractual relationship between you and us.
<br/>
<br/>
Nothing in this disclaimer will:
<ul id="tc-disclaimer-list">
<li>limit or exclude any liability for death or personal injury resulting from negligence;</li>
<li>limit or exclude any liability for fraud or fraudulent misrepresentation;</li>
<li>limit any liabilities in any way that is not permitted under applicable law; or</li>
<li>exclude any liabilities that may not be excluded under applicable law.</li>
</ul>
<br/>
This Site may contain links to other sites. These sites are not under our control and we cannot be responsible for any actions or events arising from you following any links from this Site. Links are provided for informational purposes only and should note be interpreted as approval or endorsement by us.
<br/>
<br/>
Any comments on our blog or social media channels are not endorsed or verified by us. We are not responsible for the comments on our Site made by third parties. Should we become aware of comments which are likely to be distressing we will remove them from our Site. Removal of comments does not indicate that any compensation will be awarded, it will not, comments are removed to ensure enjoyment for Site users. 
<br/>
<br/>
We have an affiliate scheme which means that we work with carefully selected partners who promote our products and services and receive a commission payment for every sale. From time to time we also partner with third parties and recommend their products and services for which we may receive commission or payment in kind. In all circumstances we make full disclosures as to our affiliate partners and never charge you more.
<br/>
<br/>
<br/>
<strong>JURISDICTION AND DISPUTE RESOLUTION</strong>
<br/>
These Terms are governed by the laws of England & Wales. The Courts of England & Wales have exclusive jurisdiction for any matter and proceedings arising out of the use of this Site. 
<br/>
<br/>
<br/>
<strong>COOKIES POLICY</strong>
<br/>
This Site uses cookies; small files of letter and numbers that are automatically placed on your machine – if you agree - to help our Site provide a better user experience. 
<br/>
<br/>
Cookies do not typically include identifying personal information but cookies may also be linked to personal information which is stored about you.
<br/>
<br/>
We use cookies to retain your user preferences, store information from elements of our Site such as shopping carts, and to provide anonymised tracking data to third party applications such as Google Analytics. These cookies are likely to be analytical/performance cookies or targeting cookies. We have no control over third party cookies.
<br/>
<br/>
In general, cookies should make your browsing experience better. You may prefer to disable cookies for this Site which you can do by disabling cookies in your browser. We suggest following the steps via the ‘Help’ tool of your browser. Please note that if you change your browser settings to block all cookies (including essential cookies) you may not be able to access some or all of our Site.
<br/>
<br/>
Except for essential cookies, all cookies expire within a reasonable period of time.
<br/>
<br/>
<br/>

<strong>MISCELLANEOUS</strong>
<br/>
Whilst using this Site you may provide personal information about yourself including your name and email address. When providing these details, you agree to provide accurate and up to date information.
<br/>
<br/>
For the avoidance of doubt the Terms of Use stand as terms only, there is no intention to create a contract. 
<br/>
<br/>
<br/>

      </div>
    </div>
  );
};

export default WithNavbar(termsconditions);
