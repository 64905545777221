import React from "react";
import { useState, useEffect } from "react";
import { WithNavbar } from "../layout/WithNavbar";
import GIC from "../../utils/downloads/GIC_Swipe_Copy_Email.pdf";
import Swatches from "../../utils/downloads/GIC_Client_Swatch_Instructions_2.pdf"
import { Container, Row } from "react-bootstrap";

const Demo = () => {
  let [ setIsLoading] = useState(true);

  // const hideSpinner = () => {
  //   setLoading(false);
  // };

  useEffect(() => {
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2500);
  });

  return (
    <div className="demo-page-container">
      <Container fluid>
        <Row lg={12} md={12} sm={12} xs={12} className="row-1-video">
        <div className="video-container">
        <h1>Main Draping Demo Video</h1>
        <iframe
              id="video-player"
              width="700"
              height="420"
              src="https://www.youtube.com/embed/3CImbmQmkDI"
              title="YouTube video player"
              allowFullScreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            ></iframe>
            <div className="demo-anchor">
              {/* <div className="dummy-row" download>
                .
              </div> */}
            </div>
            </div>
        </Row>
        <hr />
        <Row lg={12} md={12} sm={12} xs={12} className="row-2-video">
        <div className="video-container">
        <h1>Peparing Your Clients Photo</h1>
        <iframe
                id="video-player"
                width="700"
                height="420"
                src="https://www.youtube.com/embed/6ZI9knUtnmo"
                frameBorder="0"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              ></iframe>
              <div className="demo-video-anchor">
              <a href={GIC} download>
                Download Client Email Swipe File
              </a>
            </div>
            </div>
        </Row>
        <hr />
        <Row lg={12} md={12} sm={12} xs={12} className="row-3-video">
        <div className="video-container">
        <h1>Client Colour Swatch Demo</h1>
        <iframe
                id="video-player"
                width="700"
                height="420"
                src="https://www.youtube.com/embed/pxGoaopzozU"
                frameBorder="0"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
              ></iframe>
              <div className="demo-video-anchor">
              <a href={Swatches} download>
                Download Client Swatch Instructions
              </a>
            </div>
            </div>
        </Row>
      </Container>
    </div>
  );
};

export default WithNavbar(Demo);
