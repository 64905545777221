import React from "react";
import { WithNavbar } from "../layout/WithNavbar";
import { Row, Col } from "react-bootstrap";
import ColourEye from "../../utils/images/Colourful_Min.jpeg"

const ThankYou = () => {
  return (
    <div className="main-container">
      <h1>Thank You!</h1>
      <div className="thank-you-container">
        <Row>
          <Col lg={7} md={12} sm={12} id="subscription-thanks-text">
            Thank you for signing up to the Waitlist for the Glow In Colour
            Platform.
            <br />
            <br />
            You will receive an email to confirm you are on the list, and then
            we will update you on progress through the launch period leading up
            to Glow In Colour going Live!
            <br />
            <br />
            We will be offering some exclusive discounts and special offers to
            early adopters so please add our email to your white list, and keep
            an eye out for updates over the next couple of weeks!
            <br />
            <br />
            We can't wait to unveil Glow In Colour, the new online platform for
            Colour Stylists who want to work globally, and look forward to
            seeing you on the inside!
            <br />
            <br />
          </Col>
          {/* <Col lg={0} md={1}/>  */}
            <Col lg={5} md={12} sm={12} id="subscription-thanks-rhs">
            <img
                id="subscription-thanks-img"
                src={ColourEye}
                alt="Colour Eye Image"
              />
              </Col>
        </Row>
      </div>
    </div>
  );
};

export default WithNavbar(ThankYou);
