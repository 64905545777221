import { withRouter } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import AlertContext from "../../../../context/alert/alertContext";
import AuthContext from "../../../../context/Auth/AuthContext";
import GIC from "../../../../utils/images/GIC.png";
import TopHeader from "../../TopHeader";
import Footer from "../../Footer.js";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
// import Spinner from "../../utils/images/DoubleRingLoad.gif";

const CreateAccount = (props) => {
  const [subscriptions] = useState([]);
  const [imgPreview, setImgPreview] = useState(null);
  const [err, setErr] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const { setAlert, removeAlert } = alertContext;

  const { register, convertKit, error, clearErrors, isAuthenticated, emailQualification } = authContext;

  useEffect(() => {
    // const fetchData = async () => {
    //   const {subscriptions} = await fetch('api/payment/subscriptions').then(r => r.json());

    //   setSubscriptions(subscriptions.data);
    // }
    // fetchData();

    if (isAuthenticated) {
      props.history.push("/");
    }

    if (error === "User already exists") {
      setAlert(error, "danger");
      clearErrors();
    }
    //eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [user, setUser] = useState({
    name: "",
    email: "",
    email2: "",
    password: "",
    password2: "",
  });

  const { name, email, email2, password, password2 } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  const handleImageChange = (e) => {
    setSelectedFile(null)
    setImgPreview(null)
    setErr(false);
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setImgPreview(reader.result);
        setSelectedFile(selected)
      };
      reader.readAsDataURL(selected);
    } else {
      setErr(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeAlert();
    let _id = uuidv4();

    if (name === "" || email === "" || password === "") {
      setAlert("Please enter all fields", "danger");
    } else if (email !== email2) {
      setAlert("Emails do not match", "danger");
    } else if (password !== password2) {
      setAlert("Passwords do not match", "danger");
    } else if (password.length < 6) {
      setAlert("Password must contain at least 6 characters", "danger");
    } else if (selectedFile == null) {
      setAlert("Image must be uploaded", "danger");
    } else {
      let data = new FormData();
      data.append('image', selectedFile)
      data.append('name', name)
      data.append('email', email)
      register({
        _id,
        name,
        email,
        password,
        data
      })
    }
  };

  if (!subscriptions) {
    return "";
  }

  return (
    <div>
      <TopHeader />
      <div className="home-container">
        <h1>
          <div id="home-img">
            <br />
            <img className="img-fluid" src={GIC} alt="GIC Image"></img>
            <br />
          </div>
        </h1>
      </div>

      <div className="main-container" id="create-acc-main">
        Please create your account below - please note, you have now paid for
        your subscription, should you not complete your account creation
        successfully please contact us using the link at the bottom of the page.
        <br />
        <div className="form-container" id="create-acc-container">
          <div className="form-top-container" id="create-account-title">
            <h1 className="reg-title">
              Create <span className="form-text-primary">Account</span>
            </h1>
            <div className="form-backdrop reg-form-color"></div>
          </div>

          <form id="reg-form create-form" encType="multipart/form-data" onSubmit={onSubmit}>
            <div className="form-group">
              {/* <label htmlFor="name">Full Name</label> */}
              <i className="fas fa-user"></i>
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={name}
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="email">Email Address</label> */}
              <i className="fas fa-at"></i>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={onChange}
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="email">Confirm Email Address</label> */}
              <i className="fas fa-at"></i>
              <input
                type="email"
                name="email2"
                placeholder="Confirm Email Address"
                value={email2}
                onChange={onChange}
                required
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="password">Password</label> */}
              <i className="fas fa-lock"></i>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={onChange}
                required
                minLength="6"
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="password2">Confirm Password</label> */}
              <i className="fas fa-lock"></i>
              <input
                type="password"
                name="password2"
                placeholder="Confirm Password"
                value={password2}
                onChange={onChange}
                required
                minLength="6"
              />
            </div>
            <div id="qualification-text">
              Please upload your certificate of qualification in colour analysis
              below:
            </div>
            <div className="upload-certif-cont">
              {/* <label>Attachment:</label> */}
              <input
                type="file"
                name="image"
                accept="image/*, application/pdf"
                id="upload-certif-input"
                onChange={handleImageChange}
                required
              />
              <br />
              {imgPreview ? (
                <div id="certif-img">
                  <img src={imgPreview} alt="Certificate Image" />
                </div>
              ) : null}
            </div>
            <input
              type="submit"
              value="Create Account"
              className="submit-btn reg-submit"
              id="create-account-submit"
            ></input>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(CreateAccount);
