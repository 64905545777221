import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { WithNavbar } from "../../layout/WithNavbar";
import "bootstrap/dist/css/bootstrap.css";
import { DrapeSvg } from "../DrapesComponents/DrapeSvg"
import { useDraggable } from "use-draggable";

const Winter = () => {
    const colors_one = ["#AD0C44", "#073D20", "#FFFF31"];
    const colors_two = ["#47260f", "#820b5a", "#003366"];
    const colors_three = ["#360989", "#1b7931", "#3B6B4A", "#032260"];
    const colors_four = ["#4d0013","#9E3A67", "#4C4B4B", "#151838"];
    const neutrals_one = ["#FFFFFF", "#161C2B", "#373E4F", "#253456"];
    const neutrals_two = ["#33547A", "#32373D", "#272A2D"];
  
    const [showDrape, setShowDrape] = useState(false);
    const [scale, setScale] = useState("1");
    const [background, setBackground] = useState(null);
    const [imgPreview, setImgPreview] = useState(null);
    const [error, setError] = useState(false);
  
    const { targetRef, handleRef } = useDraggable({ controlStyle: true });
  
    const handleDrapeChange = (e) => {
      setShowDrape(!showDrape);
    };
  
    const handleImageChange = (e) => {
      setError(false);
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        let reader = new FileReader();
        reader.onloadend = () => {
          setImgPreview(reader.result);
        };
        reader.readAsDataURL(selected);
      } else {
        setError(true);
      }
    };
  
    const increaseDrape = () => {
      const currentScale = parseFloat(scale);
      const newScale = currentScale + 0.02;
      if (newScale > 1.8) alert("Drape cannot be any bigger");
      else setScale(newScale.toString());
    };
  
    const decreaseDrape = () => {
      const currentScale = parseFloat(scale);
      const newScale = currentScale - 0.02;
      if (newScale < 0.5) alert("Drape cannot be any smaller");
      else setScale(newScale.toString());
    };
    const moveUp = () => {
      let currentImageTop = document.getElementById("currentImage").style.top;
      let currentImageTopNo = currentImageTop.replace("%", "");
      if (currentImageTopNo <= -90) {
        alert("Cannot move up any higher");
      } else {
        document.getElementById("currentImage").style.top =
          (currentImageTopNo.toString() - 5).toString() + "%";
      }
    };
  
    const moveDown = () => {
      let currentImageTop = document.getElementById("currentImage").style.top;
      let currentImageTopNo = currentImageTop.replace("%", "");
      let newCurrentImageTop = parseInt(currentImageTopNo.replace("%", ""));
      if (isNaN(newCurrentImageTop)) {
        newCurrentImageTop = 0;
      }
      newCurrentImageTop += 5;
      if (newCurrentImageTop >= 90) {
        alert("Cannot move down any lower");
      } else {
        document.getElementById("currentImage").style.top =
          newCurrentImageTop.toString() + "%";
      }
    };
  
    const moveRight = () => {
      let currentImageLeft = document.getElementById("currentImage").style.left;
      let currentImageleftNo = currentImageLeft.replace("%", "");
      let newCurrentImageLeft = parseInt(currentImageleftNo.replace("%", ""));
      if (isNaN(newCurrentImageLeft)) {
        newCurrentImageLeft = 0;
      }
      newCurrentImageLeft += 10;
      if (newCurrentImageLeft >= 70) {
        alert("Cannot move right any further");
      } else {
        document.getElementById("currentImage").style.left =
          newCurrentImageLeft.toString() + "%";
      }
    };
  
    const moveLeft = () => {
      let currentImageLeft = document.getElementById("currentImage").style.left;
      let currentImageleftNo = currentImageLeft.replace("%", "");
      let newCurrentImageLeft = parseInt(currentImageleftNo.replace("%", ""));
      if (isNaN(newCurrentImageLeft)) {
        newCurrentImageLeft = 0;
      }
      newCurrentImageLeft -= 10;
      if (newCurrentImageLeft <= -70) {
        alert("Cannot move left any further");
      } else {
        document.getElementById("currentImage").style.left =
          newCurrentImageLeft.toString() + "%";
      }
    };
  
    const resetImZoom = () => {
      let myImg = document.getElementById("uploadimg");
      myImg.style.width = "100%";
    };
  
    const zoomIn = () => {
      let containerWidth = document.getElementById("imgContainer").offsetWidth;
      let myImg = document.getElementById("uploadimg");
      let currWidth = myImg.clientWidth;
      let currHeight = myImg.clientHeight;
      if (currWidth >= containerWidth) {
        alert("Maxium zoom in has been reached");
      } else {
        myImg.style.width = currWidth + 50 + "px";
        myImg.style.height = currHeight + 50 + "px";
      }
    };
  
    const zoomOut = () => {
      let myImg = document.getElementById("uploadimg");
      let currWidth = myImg.clientWidth;
      // let currHeight = myImg.clientHeight;
      if (currWidth <= 200) {
        alert("Maxium zoom out has been reached");
      } else {
        myImg.style.width = currWidth - 50 + "px";
      }
    };
  
    return (
      <div className="drapes-page">
        <Container fluid>
          <Row className="flex-md-row">
            <Col className="id-drapes-test" sm={2} xs={6} id="drapes-col-1">
              <p id="color-title">Winter Colours</p>
              <Row className="test-colour-col">
                <div className="c-container">
                  {colors_one.map((color, index) => (
                    <div key={index} className="c-card">
                      <div
                        style={{
                          background: color,
                          filter: "brightness(95%)",
                          boxShadow: color === background ? "0 0 5px #000" : "",
                        }}
                        className="c-box"
                        onClick={() => setBackground(color)}
                      />
                    </div>
                  ))}
                </div>
              </Row>
              <Row className="test-colour-col">
                <div className="c-container">
                  {colors_two.map((color, index) => (
                    <div key={index} className="c-card">
                      <div
                        style={{
                          background: color,
                          filter: "brightness(95%)",
                          boxShadow: color === background ? "0 0 5px #000" : "",
                        }}
                        className="c-box"
                        onClick={() => setBackground(color)}
                      />
                    </div>
                  ))}
                </div>
              </Row>
              <br />
              <p id="color-title">Winter Additional Colours</p>
              <Row className="test-colour-col">
                <div className="c-container">
                  {colors_three.map((color, index) => (
                    <div key={index} className="c-card">
                      <div
                        style={{
                          background: color,
                          filter: "brightness(95%)",
                          boxShadow: color === background ? "0 0 5px #000" : "",
                        }}
                        className="c-box"
                        onClick={() => setBackground(color)}
                      />
                    </div>
                  ))}
                </div>
              </Row>
              <Row className="test-colour-col">
                <div className="c-container">
                  {colors_four.map((color, index) => (
                    <div key={index} className="c-card">
                      <div
                        style={{
                          background: color,
                          filter: "brightness(95%)",
                          boxShadow: color === background ? "0 0 5px #000" : "",
                        }}
                        className="c-box"
                        onClick={() => setBackground(color)}
                      />
                    </div>
                  ))}
                </div>
              </Row>
            </Col>
            <Col className="id-drapes-test" sm={8} xs={12} id="drapes-col-2">
              <div className="container">
                <div className="drapes-container">
                  <div className="top-btn-container">
                    {imgPreview ? (
                      <button
                        class="btn-col-one"
                        id="show-drape"
                        onClick={() => handleDrapeChange()}
                      >
                        {showDrape ? "Hide Drape" : "Show Drape"}
                      </button>
                    ) : (
                      <button id="show-drape" className="btn-disabled">
                        Hide Drape
                      </button>
                    )}
                    {imgPreview && (
                      <button
                        className="rst-btn btn-col-three"
                        onClick={() => {
                          setImgPreview(null);
                          resetImZoom();
                          setShowDrape(null);
                        }}
                      >
                        Remove Image
                      </button>
                    )}
                    <button
                      id="show-drape"
                      className="btn-disabled"
                      onClick={() => increaseDrape()}
                    >
                      <i className="fas fa-plus" id="drape-btn"></i>
                    </button>
                    <button
                      id="show-drape"
                      className="btn-disabled"
                      onClick={() => decreaseDrape()}
                    >
                      <i className="fas fa-minus" id="drape-btn"></i>
                    </button>
                  </div>
                  <div className="img-drape-container">
                    <div className="image-container" id="imgContainer">
                      {error && <p className="errMsg">File not supported</p>}
  
                      <div className="imgPreview" id="uploadimg">
                        <div className="drape-img" ref={targetRef}>
                          <DrapeSvg
                            ref={handleRef}
                            showDrape={showDrape}
                            drapeColour={background}
                            scale={scale}
                          />
                        </div>
                        {!imgPreview ? (
                          <>
                            <p>Add an image</p>
                            <label
                              className="customFileUpload"
                              htmlFor="fileUpload"
                            >
                              Choose File
                            </label>
                            <input
                              type="file"
                              id="fileUpload"
                              onChange={handleImageChange}
                            />
                            <span>(jpg, jpeg or png)</span>
                          </>
                        ) : (
                          <div>
                            <img
                              src={imgPreview}
                              alt="Uploaded Image"
                              id="currentImage"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-containers">
                  <div className="drape-btn-container">
                    {imgPreview && (
                      <button
                        className="rst-btn btn-col-one"
                        type="button"
                        onClick={() => zoomIn()}
                      >
                        Zoom In
                      </button>
                    )}
                    {imgPreview && (
                      <button
                        className="rst-btn btn-col-two"
                        type="button"
                        onClick={() => zoomOut()}
                      >
                        Zoom Out
                      </button>
                    )}
                    {imgPreview && (
                      <button
                        className="rst-btn btn-col-three"
                        type="button"
                        onClick={() => moveUp()}
                      >
                        Move Up
                      </button>
                    )}
                    {imgPreview && (
                      <button
                        className="rst-btn btn-col-one"
                        type="button"
                        onClick={() => moveDown()}
                      >
                        Move Down
                      </button>
                    )}
                    {imgPreview && (
                      <button
                        className="rst-btn btn-col-two"
                        type="button"
                        onClick={() => moveLeft()}
                      >
                        Move Left
                      </button>
                    )}
                    {imgPreview && (
                      <button
                        className="rst-btn btn-col-three"
                        type="button"
                        onClick={() => moveRight()}
                      >
                        Move Right
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col className="id-drapes-test" sm={2} xs={6} id="drapes-col-3">
              <p id="color-title">Winter Neutrals</p>
              <Row className="test-colour-col">
                <div className="c-container">
                  {neutrals_one.map((color, index) => (
                    <div key={index} className="c-card">
                      <div
                        style={{
                          background: color,
                          filter: "brightness(95%)",
                          boxShadow: color === background ? "0 0 5px #000" : "",
                        }}
                        className="c-box"
                        onClick={() => setBackground(color)}
                      />
                    </div>
                  ))}
                </div>
              </Row>
              <p id="color-title">Winter Secondaries to be worn away from the face</p>
              <Row className="test-colour-col">
                <div className="c-container">
                  {neutrals_two.map((color, index) => (
                    <div key={index} className="c-card">
                      <div
                        style={{
                          background: color,
                          filter: "brightness(95%)",
                          boxShadow: color === background ? "0 0 5px #000" : "",
                        }}
                        className="c-box"
                        onClick={() => setBackground(color)}
                      />
                    </div>
                  ))}
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
export default WithNavbar(Winter);
