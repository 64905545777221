import React from 'react'
import { WithNavbar } from '../../WithNavbar';

const AccessDenied = () => {
    return (
        <div className="main-container">
            <h1>Access Denied</h1>
            Cannot access this page as you has not provided a valid id parameter.
        </div>
    )
}

export default WithNavbar(AccessDenied);
