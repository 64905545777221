import React from "react";

const ContactForm = () => {
  
const email = "https://formsubmit.co/hello@glowincolour.com"
const thanks = window.location.origin + "/contact-us-thank-you";

  return (
    <div className="container">
      <div className="contact-form-container">
      <form id="contact-form" action={email} method="POST">
        <div className="contact-form-group">
          <i className="fas fa-user"></i>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
          />
        </div>
        <br />

        <div className="contact-form-group">
          <i className="fas fa-at"></i>
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
          />
        </div>
        <br />

        <div className="contact-form-group">
        <i className="fas fa-mail-bulk"></i>
          <input
            type="text"
            name="subject"
            placeholder="Message Subject"
            required
          />
        </div>
        <br />

        <div className="form-group-message">
          <textarea name="message" id="" cols="36" rows="5" placeholder="Message" required ></textarea>
        </div>
        <br />

        <input type="hidden" name="_next" value={thanks}/>

        <input
          type="submit"
          value="Submit"
          className="submit-btn contact-us-submit"
        ></input>
      </form>
      </div>
    </div>
  );
};

export default ContactForm;
