import { WithNavbar } from "../../WithNavbar";
import { Link, Redirect } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import AlertContext from "../../../../context/alert/alertContext";
import AuthContext from "../../../../context/Auth/AuthContext";

const Prices = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);

  const [customer] = useState(null);

  const { setAlert } = alertContext;

  const { error, clearErrors, isAuthenticated } = authContext;

    // test key = "pk_test_51JKR20JWstDKcbZIvqHSyws8FIGZqtGVDBzF9jnsGmIcHAUZ0ZkDllkx4qNnY683cnPb3HGfTsFtgMSYqnLfukFI00Y183sJ23"
  let stripe = window.Stripe(
    "pk_live_51JKR20JWstDKcbZIjF6ag2Sl6S4n9ueUHBTWIdxD4rAg4q2onTAwQaZm6x30K93MsvEKT5YnUcMiKjeNQUCgtGFk00AQUapBIQ"
  );

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push("/");
    }

    if (error === "User already exists") {
      setAlert(error, "danger");
      clearErrors();
    }
    //eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const onSubmit = async (e) => {
    e.preventDefault();

    fetch("api/payment/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        quantity: 1,
      }),
    })
      .then((response) => response.json())
      .then((session) => {
        // console.log('Success:', data);
        stripe.redirectToCheckout({ sessionId: session.id });
      })
      .then((error) => {
        console.log("Error:", error);
      });
  };

  //When customer is created, then move to
  if (customer) {
    return <Redirect to={{ pathname: "/prices" }} />;
  }

  return (
    <div className="prices-container">

      <div id="prices-breakdown">
      As part of the registration process, you will be asked to upload your
          certificate of qualification as a Colour Consultant. Please have a
          jpeg or png file ready to upload before you go to the payment page.
          <a href="https://smallpdf.com/pdf-to-jpg">Click</a> to use free online platform for conversion.
          <br />
          <br />
          Once you have registered, you have an hour to set up your account and
          access the Glow In Colour platform. If for any reason you are not able
          to set up your account within the hour, please contact us for more
          help.
          <br />
          <br />
      </div>

      <div className="form-container" id="prices-box">
        <div className="form-top-container">
          <div className="form-backdrop login-form-color"></div>
        </div>
        <div id="pcm">
          <div>Monthly Subscription</div>
          <p id="money-price">${6700 / 100} per month</p>
        </div>
        <br />
        <p>
          Our glow in colour pricing currently consists of a joining fee of
          <strong id="join-price">$99</strong>with a monthly subscription of
          <strong id="join-price">$67</strong>starting 30 days from day of
          subscription.
        </p>

        <br />
        {/* <p>
          Invoices will be sent via email to the address used in previous page.
        </p> */}

        <button
          className="submit-btn login-submit"
          onClick={(e) => onSubmit(e)}
          id="price-select-btn"
        >
          Select
        </button>
        <div className="already-link">
          <div>Already have an account? </div>
          <Link id="forgot-link" to="/login">
            Sign In
          </Link>
        </div>
      </div>

      <div id="lond-coll-txt">
      We are delighted to work collaboratively with the award winning London College of Style who provide training for Personal Stylists and Creatives.  
      The Colour Analysis for Creatives course will give you an accreditation which will enable you to practice worldwide.
      <br/>
      <br/>
      If you are not yet fully qualified please visit <a href="https://londoncollegeofstyle.com/courses/colour-analysis-online-diploma/">London College of Style</a> for details of our 
      recommended Colour Analysis Online Diploma.
      </div>
    </div>
  );
};

export default WithNavbar(withRouter(Prices));
