import React from "react";
import CreateAccount from "../layout/homelayout/PaymentLayout/CreateAccount";
import AccessDenied from "../layout/homelayout/PaymentLayout/AccessDenied";
import LoadingScreen from "../layout/LoadingScreen";

const Register = ({ loading, checkoutData }) => {
  return loading ? ( //Checkif if is loading
    <LoadingScreen/>
  ) : (
    <div>{checkoutData != null ? <CreateAccount /> : <AccessDenied />}</div>
  );
};

export default Register;
 