import React, { Fragment, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Drapes from "../pages/Drapes";
import Spring from "../pages/DrapesSeasons/Spring";
import Autumn from "../pages/DrapesSeasons/Autumn";
import Summer from "../pages/DrapesSeasons/Summer";
import Winter from "../pages/DrapesSeasons/Winter";
// import Login from "../components/auth/Login";
import Login from "../auth/Login";
import Alerts from "../layout/Alerts";
import Messages from "../layout/Messages";
import PrivateRoute from "../routing/PrivateRoute";
import Demo from "../pages/Demo";
import Reset from "../auth/Reset";

import AlertState from "../../context/alert/AlertState";
import AuthContext from "../../context/Auth/AuthContext";
import MessageState from "../../context/message/MessageState";
import ContactUs from "../pages/ContactUs";
import privacypolicy from "../pages/legal/privacypolicy";
import termsconditions from "../pages/legal/termsconditions";
import AccountServices from "../pages/AccountServices";
import NewPassword from "../auth/NewPassword";
import ThankYou from "../pages/ThankYou";
import ContactThanks from "../pages/Contact-Thanks";
import PageNotFound from "../layout/NotFoundPage";
import OpenAtTop from "../layout/OpenAtTop";
import Prices from "../layout/homelayout/PaymentLayout/Prices";
import CreateAccount from "../layout/homelayout/PaymentLayout/CreateAccount";
import Success  from "../pages/Success";
import Cancel from "../layout/homelayout/PaymentLayout/Cancel";

const ApplicationFiles = () => {
  const authContext = useContext(AuthContext);

  const { isAuthenticated } = authContext;

  //SP - The below is needes to stay logged in...
  useEffect(() => {
    authContext.loadUser();
  }, []);

  return (
    <AlertState>
      <MessageState>
        <Router>
          <Fragment>
            <Alerts />
            <Messages />
            <OpenAtTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/register" component={Prices} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/contact-us" component={ContactUs} />
              <PrivateRoute exact path="/drapesapp" component={isAuthenticated?Drapes:Login}/>
              <PrivateRoute exact path="/drapesapp/spring" component={isAuthenticated?Spring:Login}/>
              <PrivateRoute exact path="/drapesapp/autumn" component={isAuthenticated?Autumn:Login}/>
              <PrivateRoute exact path="/drapesapp/winter" component={isAuthenticated?Winter:Login}/>
              <PrivateRoute exact path="/drapesapp/summer" component={isAuthenticated?Summer:Login}/>
              <PrivateRoute exact path="/account-services" component={isAuthenticated?AccountServices:Home}/>
              <Route exact path="/privacy-policy" component={privacypolicy} />
              <Route exact path="/thank-you" component={ThankYou} />
              <Route exact path="/terms-and-conditions" component={termsconditions}/>
              <PrivateRoute exact path="/demo" component={isAuthenticated?Demo:Login} />
              <Route exact path="/reset" component={Reset} />
              <Route exact path="/resetw:token" component={NewPassword} />
              <Route exact path="/contact-us-thank-you" component={ContactThanks} />
              <Route exact path="/prices" component={Prices}/>
              <Route exact path="/success" component={Success}/>
              <Route exact path="/cancel" component={Cancel}/>
              <Route exact path="/create-account" render={(props) => <CreateAccount {...props}/>}/>
              <Route path="*" component={PageNotFound} /> 
            </Switch>
            {/* <Footer /> */}
          </Fragment>
        </Router>
      </MessageState>
    </AlertState>
  );
};

export default ApplicationFiles;
