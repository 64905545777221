import React, { useContext } from "react";
import AuthContext from "../../context/Auth/AuthContext";
// import "react-bootstrap"

const LogoutModal = ({ onClose, showModal }) => {
  const authContext = useContext(AuthContext);

  const { logout } = authContext;

  const onLogout = () => {
    logout();
    window.location.reload();
  };


  return (
    <div>
        <div className="mdl-bg">
          <div className="mdl-content">
          <i className="fas fa-times" id="mdl-exit" onClick={onClose}/>
            <div className="mdl-header">
              <h4 className="mdl-title">Logout</h4>
            </div>
            <div className="mdl-body">Are you sure you want to log out?</div>
            <div className="mdl-footer">
              <button className="mdl-button" id="logout-button" onClick={() => {onLogout()}}>Logout</button>
              <button className="mdl-button" id="close-button" onClick={onClose}>Close</button>
            </div>
          </div>
        </div>
    </div>
  );
};

export default LogoutModal;
