import React from "react";
import { WithNavbar } from "../layout/WithNavbar";
import Robot from "../../utils/images/404Robot.svg";

const PageNotFound = () => {
    return ( 
        
        <div className="main-container">
            <img className="not-found-img" alt="not-found-robot" src={Robot}></img>
            <div id="return-link">Click <a href="/">here</a> to navigate back to the home page </div>
        </div>
     );
}
 
export default WithNavbar(PageNotFound);